import React, { useState, useEffect } from 'react'
import MultioptionSwitch from "./components/MultioptionSwitch";
import { useSocket } from './api/socket';

export default function App() {
	const category = (cat) => {
		switch (cat) {
			case 'Зарплата':
				return <div className="">Зарплата</div>;
			case 'Фріланс/підробіток':
				return <div className="">Фріланс/підробіток</div>;
			case 'Подарунок':
				return <div className="">Подарунок</div>;
			case 'Займ':
				return <div className="">Подарунок</div>;
			case 'Їжа та напої':
				return <div className="">Їжа та напої</div>;
			case 'Житло та комунальні послуги':
				return <div className="">Житло та комунальні послуги</div>;
			case 'Транспорт':
				return <div className="">Транспорт</div>;
			case 'Розваги':
				return <div className="">Розваги</div>;
			case 'Подорожі':
				return <div className="">Подорожі</div>;
			case 'Одяг та взуття':
				return <div className="">Одяг та взуття</div>;
			case "Здоров'я та краса":
				return <div className="">Здоров'я та краса</div>;
			case 'Домашні потреби':
				return <div className="">Домашні потреби</div>;
			case 'Кредити':
				return <div className="">Кредити</div>;
			case 'Перевод між картками':
				return <div className="">Перевод між картками</div>;
			case 'Інше':
				return <div className="">Інше</div>;
			default:
				return <div className="">Невідома категорія</div>;
		}
	}

	const data = useSocket();

	const [typeFilter, setTypeFilter] = useState('all');
	const [cardFilter, setCardFilter] = useState('all');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	if (!data) {
		return <div>Loading...</div>;
	}

	const {balances, transactions, cards} = data;

	const parseDate = (dateString) => {
		const [day, month, year] = dateString.split('-');
		return new Date(`${year}-${month}-${day}`);
	};
	
	const filteredRows = transactions.filter(row => {
		// Фільтруємо за типом транзакції (income/expense)
		const typeMatch = typeFilter === 'all' || row.type === typeFilter;
	
		// Фільтруємо за карткою
		let cardMatch = false;
	
		// Якщо обрана картка готівка
		if (cardFilter === 'cash' && row.card === 'cash') {
			cardMatch = true;
		}
		
		// Якщо фільтр встановлений на всі картки
		else if (cardFilter === 'all') {
			cardMatch = true;
		} 
		
		// Якщо картка вибрана за конкретним фільтром (Max/Vika)
		else if (cardFilter === 'Max' || cardFilter === 'Vika') {
			const localCards = cardFilter === 'Max' ? cards.maxCards : cards.vikaCards;
			cardMatch = localCards.some(card => card.cardNumShort === row.card);
		}
	
		// Фільтруємо за датою
		const dateMatch = (!startDate || parseDate(row.date) >= new Date(startDate)) &&
						  (!endDate || parseDate(row.date) <= new Date(endDate));
	
		return typeMatch && cardMatch && dateMatch;
	});

	function getCard(cardNum) {
		if (cardNum === 'cash') return 'cash';
	
		// Перевіряємо серед карт Макса
		let card = cards.maxCards.find(card => card.cardNumShort === cardNum);
		if (card) return card;
	
		// Перевіряємо серед карт Віки
		card = cards.vikaCards.find(card => card.cardNumShort === cardNum);
		return card;
	}

	return (
		<div className="flex flex-col items-center gap-4">
			
			<div className="w-full py-4 flex justify-center gap-4 fixed z-20 bg-base-100 shadow-lg">

				<div className="border border-accent rounded-xl overflow-clip w-60">
					<div className="px-4 py-2 bg-accent text-accent-content text-3xl font-bold text-center">{(balances.maxCards + balances.vikaCards).toFixed(2)} ₴</div>
					<div className="px-4 py-2 text-accent text-center">Загальний баланс</div>
				</div>

				<div className="border border-primary rounded-xl overflow-clip w-60">
					<div className="px-4 py-2 bg-primary text-primary-content text-3xl font-bold text-center">{balances.maxCards.toFixed(2)} ₴</div>
					<div className="px-4 py-2 text-primary text-center">Баланс Макс</div>
				</div>

				<div className="border border-secondary rounded-xl overflow-clip w-60">
					<div className="px-4 py-2 bg-secondary text-secondary-content text-3xl font-bold text-center">{balances.vikaCards.toFixed(2)} ₴</div>
					<div className="px-4 py-2 text-secondary text-center">Баланс Віка</div>
				</div>

				<div className='divider divider-horizontal'></div>

				<div className="border border-warning rounded-xl overflow-clip w-60">
					<div className="px-4 py-2 bg-warning text-warning-content text-3xl font-bold text-center">{balances.cash.toFixed(2)} ₴</div>
					<div className="px-4 py-2 text-warning text-center">Готівка</div>
				</div>

			</div>

			<div className="w-full px-[16rem] mt-[8rem]">
				<div className="flex gap-4 py-2">

					<MultioptionSwitch optionColors={['primary', 'success', 'error']} onChange={(index) => {
						const types = ['all', 'income', 'expense']; 
						setTypeFilter(types[index]);
					}}>
						<span>Всі</span>
						<span>Надходження</span>
						<span>Витрати</span>
					</MultioptionSwitch>

					<MultioptionSwitch optionColors={['accent', 'primary', 'secondary', 'warning']}  onChange={(index) => {
						const cards = ['all', 'Max', 'Vika', 'cash'];
						setCardFilter(cards[index]);
					}}>
						<span>Всі</span>
						<span>Макс</span>
						<span>Віка</span>
						<span>Готівка</span>
					</MultioptionSwitch>
					
					<div className='flex gap-1 w-full justify-end'>
						<input 
							type="date" 
							className="input input-bordered border-primary text-primary focus:outline-none focus:border-accent focus:text-accent duration-300 rounded-xl" 
							value={startDate} 
							onChange={(e) => setStartDate(e.target.value)} 
						/> 
						<span className='flex items-center'>-</span>
						<input 
							type="date" 
							className="input input-bordered border-primary text-primary focus:outline-none focus:border-accent focus:text-accent duration-300 rounded-xl" 
							value={endDate} 
							onChange={(e) => setEndDate(e.target.value)} 
						/>
					</div>
				</div>

				<div className="overflow-x-auto max-h-[46rem]">
					<table className="table">
						{/* head */}
						<thead className="sticky top-0 bg-base-100 shadow-lg">
							<tr>
								<th></th>
								<th>Дата</th>
								<th>Тип</th>
								<th>Сума</th>
								<th>Категорія</th>
								<th>Опис</th>
								<th>Картка</th>
							</tr>
						</thead>
						<tbody>
							{filteredRows.reverse().map((row, index) => 
								<tr key={row._id}>
									<th>{index + 1}</th>
									<td>{row.date} {row.time}</td>
									<td>{row.type === 'income' ? <div className="badge badge-success">Надходження</div> : <div className="badge badge-error">Витрата</div>}</td>
									<td className={`text-${row.type === 'income' ? 'success' : 'error'}`}>
										{`${row.type === 'income' ? '+' : '-'}${row.amount.toFixed(2)}₴`}
									</td>
									<td>{category(row.category)}</td>
									<td className="w-[30rem]">{row.description}</td>
									<td>
										{getCard(row.card) !== 'cash' ? 
											<div className={`cursor-pointer badge badge-outline ${getCard(row.card).owner === 'Max' ? 
												'hover:bg-primary hover:text-primary-content badge-primary' : 
												'hover:bg-secondary hover:text-primary-content badge-secondary'}`}>
													{`${getCard(row.card).bank}: ${getCard(row.card).cardNumShort}`}
											</div>
											:
											<div className={`badge badge-outline badge-warning`}>Готівка</div>
										}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

		</div>
	);
}

