import { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';

const ENDPOINT = 'https://mm.maxraimer.com:3050';
// const ENDPOINT = 'http://localhost:3050';

export const useSocket = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);

        socket.emit('request_data');

        // Отримуємо дані з сервера
        socket.on('data', (newData) => {
            setData(newData);
        });

        // Очищення після завершення компоненту
        return () => {
            socket.disconnect();
        };
    }, []);

    return data;
};