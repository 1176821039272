import React, { useEffect, useState } from 'react';

const MultioptionSwitch = ({ children, optionColors, onChange }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [chosenColor, setChosenColor] = useState(optionColors[activeIndex]);

    useEffect(() => {
        setChosenColor(optionColors[activeIndex]);
    }, [activeIndex, optionColors]);

    const handleSwitch = (index) => {
        setActiveIndex(index);
        if (onChange) {
            onChange(index);
        }
    };

    return (
        <div className={`inline-flex rounded-xl border ${optionColors ? `border-${chosenColor}` : 'border-primary'} overflow-clip duration-300`}>
            {React.Children.map(children, (child, index) => (
                <button
                    key={index}
                    className={`duration-300 px-4 py-2 transition-colors ${
                        index === activeIndex
                            ? optionColors ? `bg-${chosenColor} text-base-100` : 'bg-primary text-primary-content'
                            : optionColors ? `text-${chosenColor}` : 'text-primary'
                    }`}
                    onClick={() => handleSwitch(index)}
                >
                    {child}
                </button>
            ))}
        </div>
    );
};

export default MultioptionSwitch;
